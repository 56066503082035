<template>
  <div>
    <v-card flat>
      <v-expand-transition>
        <CustomerMonitoringFilter v-show="showFilters" @clearFilterEvent="onClearFilterEvent" @applyCustomerMonitoringFilter="initBudgetMonitoring()"></CustomerMonitoringFilter>
      </v-expand-transition>
      <v-data-table dense :loading-text="$t('loading')" sort-by="name,code,suff" class="elevation-1" :headers="headers" :items="customerMonitoringData" :search="searchInTable" :items-per-page="10" :footer-props="{ showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right' }">
        <template v-slot:top>
          <v-toolbar flat class="p">
            <v-toolbar-title v-t="'customerMonitoring'"></v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-row class="d-flex mt-3">
              <v-col cols="12" sm="6">
                <v-select dense v-model="stat.tag" :items="getOrderStatisticalTagCollection" :label="$t('statisticalTag')" outlined @change="applyToggleFilters" @clear="applyToggleFilters"></v-select>
              </v-col>
              <v-col cols="12" sm="4" v-if="getPurchaseCollection.length > 1">
                <v-select dense v-model="stat.type" :items="getPurchaseCollection" :label="$t('purchaseCategory')" outlined @change="applyToggleFilters"></v-select>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-btn color="success" dark class="mb-2" @click="downloadListExport()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="info" dark class="mb-2" @click="showFilters = !showFilters">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.code="{item}">
          <td class="text-truncate" style="font-size: 0.9rem;">{{ item.code }}</td>
        </template>
        <template v-slot:item.businessName="{item}">
          <td style="word-break: keep-all">
            <v-icon small @click="openCustomerInNewTab(item)">mdi-open-in-new</v-icon>
            {{ item.businessName }}
          </td>
        </template>
        <template v-slot:item.customerGroup="{ item }" style="max-width: 60px">
          <td class="text-truncate" style="font-size: 0.9rem;">{{ item.customerGroup }}</td>
        </template>
        <template v-slot:item.customerPurchaseCategory="{ item }" style="max-width: 60px">
          <td class="text-truncate" style="font-size: 0.9rem;">{{ item.customerPurchaseCategory }}</td>
        </template>
        <template v-slot:item.ordersPYAC="{ item }">
          <td class="text-truncate number-style">{{ item.ordersPYAC | tenantCurrency }}</td>
        </template>
        <template v-slot:item.ordersPYDC="{ item }">
          <td class="text-truncate number-style">{{ item.ordersPYDC | tenantCurrency }}</td>
        </template>
        <template v-slot:item.qtyOrdersYTD="{ item }">
          <td class="number-style">{{ !!item.qtyOrdersYTD ? item.qtyOrdersYTD : $t('na') }}</td>
        </template>
        <template v-slot:item.ordersYTDAC="{ item }">
          <td class="text-truncate number-style">{{ item.ordersYTDAC | tenantCurrency }}</td>
        </template>
        <template v-slot:item.ordersYTDDC="{ item }">
          <td class="text-truncate number-style">{{ item.ordersYTDDC | tenantCurrency }}</td>
        </template>
        <template v-slot:item.budgetCY="{ item }">
          <v-edit-dialog :return-value.sync="item.budgetCY" @close="closeUpdateBudgetCY(item)">
            <td class="text-truncate number-style">{{ item.budgetCY | tenantCurrency(true) }}</td>
            <template v-slot:input>
              <v-text-field v-model="item.budgetCY" :label="$t('edit')" single-line></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.deltaPercentageAC="{ item }">
          <td class="text-truncate number-style">{{ item.deltaPercentageAC | percentage }}</td>
        </template>
        <template v-slot:item.deltaPercentageDC="{ item }">
          <td class="text-truncate number-style">{{ item.deltaPercentageDC | percentage }}</td>
        </template>
        <template v-slot:item.qtyBudgetCY="{ item }">
          <td class="number-style">{{ !!item.qtyBudgetCY ? item.qtyBudgetCY : $t('na') }}</td>
        </template>
        <template v-slot:item.budgetYTD="{ item }">
          <td class="text-truncate number-style">{{ item.budgetYTD | tenantCurrency(true) }}</td>
        </template>
        <template v-slot:item.lastOrderDate="{ item }">
          <td style="font-size: 0.9rem; text-align: right;">{{ item.lastOrderDate | formatDate }}</td>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="pill">
            <v-btn icon raised color="secondary" @click="openModal(item)">
              <v-icon x-small>mdi-eye</v-icon>
            </v-btn>
            <MFCustomerTags :customerId="`${+item.customerId}`"/>
          </div>
        </template>
        <template v-slot:no-data>
          <NoDataImg/>
        </template>
        <template v-slot:body.append>
          <tr style="background-color: #eeeeee; font-weight: 600 !important;">
            <td colspan="3" style="font-weight: 600 !important;">{{ $t('footerMonitoring', {endDate:`${ $options.filters.formatDate(endDateYTD)}`, daysYTD:daysYTD}) }}</td>
            <td class="text-right" style="font-weight: 600 !important;"><span class="bold">{{ $t('total') }}:</span></td>
            <td class="number-style" style="font-weight: 600 !important;">{{ total[`ordersPY${monitoringSuffix}`] | tenantCurrency }}</td>
            <td v-if="showQuantity()" class="number-style" style="font-weight: 600 !important;">{{ !!total.qtyOrdersYTD ? total.qtyOrdersYTD : $t('na') }}</td>
            <td class="number-style" style="font-weight: 600 !important;">{{ total[`ordersYTD${monitoringSuffix}`] | tenantCurrency }}</td>
            <td class="number-style" style="font-weight: 600 !important;">{{
                total.budgetYTD | tenantCurrency(true)
              }}
            </td>
            <td class="number-style" style="font-weight: 600 !important;">{{ total[`deltaPercentage${monitoringSuffix}`] | percentage }}</td>
            <td v-if="showQuantity()" class="number-style" style="font-weight: 600 !important;">{{ !!total.qtyBudgetCY ? total.qtyBudgetCY : $t('na') }}</td>
            <td class="number-style" style="font-weight: 600 !important;">{{
                total.budgetCY | tenantCurrency(true)
              }}
            </td>
            <td></td>
            <td style="display: flex; flex-direction: column; align-items: center;">{{ monitoringSuffix }}</td>
          </tr>
        </template>
      </v-data-table>
      <ModalOrderRows v-if="showOrderRows" @close="closeModalOrderRows()" @explode="handleExplode($event)"></ModalOrderRows>
    </v-card>
  </div>
</template>

<script>
import {call, get, sync} from "vuex-pathify";
import ModalOrderRows from '../../components/utility/ModalOrderRows.vue';
import MFCustomerTags from '../../components/utility/MFCustomerTags';
import CustomerMonitoringFilter from "@/components/filters/CustomerMonitoringFilter";
import NoDataImg from '@/components/NoDataImg.vue';

export default {
  components: {
    CustomerMonitoringFilter,
    NoDataImg,
    ModalOrderRows,
    MFCustomerTags
  },
  data: () => ({
    toggleFilters: '',
    showOrderRows: false,
    showFilters: false,
    searchInTable: "",
    selectedRow: {},
    stat: {
      tag: -1,
      type: null
    }
  }),
  computed: {
    getOrderStatisticalTagCollection: function() {
      return [{text: 'ALL', value: -1}, ...this.orderStatisticalTagCollection]
    },
    getPurchaseCollection: function() {
      return this.orderStatisticalTagCollection.find(x => x.value == this.stat.tag)?.text?.includes('MACHINES')
        ? [{text: '-', value: null}, {text: 'Buying', value: false}, {text: 'Loan for Use', value: true}]
        : [{text: '-', value: null}]
    },
    customerMonitoringData: get("customerMonitoring/customerMonitoringData"),
    total: get("customerMonitoring/total"),
    endDateYTD: get("customerMonitoring/endDateYTD"),
    daysYTD: get("customerMonitoring/daysYTD"),
    filters: sync("customerMonitoring/filters"),
    orderStatisticalTags: get("customerMonitoring/orderStatisticalTags"),
    orderStatisticalTagCollection: get("statistics/orderStatisticalTagDropdown"),
    monitoringSuffix: get("tenantConfiguration/monitoringSuffix"),
    headers(){
      let ms = this.monitoringSuffix;
      if (this.showQuantity()) {
        return [
          {text: this.$t('code'), value: "code", align: "left"},
          {text: this.$t('businessName', {r:''}), value: "businessName", align: "left"},
          {text: this.$t('groupOrIndipendent'), align: "left", value: "customerGroup",},
          {text: this.$t('purchaseCategory'), value: "customerPurchaseCategory", align: "left",},
          {text: this.$t('ordersPY'), value: `ordersPY${ms}`, align: "right",},
          {text: this.$t('qtyOrdersYTD'), value: "qtyOrdersYTD", align: "right",},
          {text: this.$t('ordersYTD'), value: `ordersYTD${ms}`, align: "right",},
          {text: this.$t('budgetYTD'), value: "budgetYTD", align: "right",},
          {text: "%", value: `deltaPercentage${ms}`, align: "right",},
          {text: this.$t('qtyBudgetCY'), value: "qtyBudgetCY", align: "right",},
          {text: this.$t('budgetCY'), value: "budgetCY", align: "right",},
          {text: this.$t('lastOrderDate'), value: "lastOrderDate", align: "right",},
          {text: this.$t('actions'), value: "actions", sortable: false, align: "center",},
        ];
      } else {
        return [
          {text: this.$t('code'), value: "code", align: "left"},
          {text: this.$t('businessName', {r:''}), value: "businessName", align: "left"},
          {text: this.$t('groupOrIndipendent'), align: "left", value: "customerGroup",},
          {text: this.$t('purchaseCategory'), value: "customerPurchaseCategory", align: "left",},
          {text: this.$t('ordersPY'), value: `ordersPY${ms}`, align: "right",},
          {text: this.$t('ordersYTD'), value: `ordersYTD${ms}`, align: "right",},
          {text: this.$t('budgetYTD'), value: "budgetYTD", align: "right",},
          {text: "%", value: `deltaPercentage${ms}`, align: "right",},
          {text: this.$t('budgetCY'), value: "budgetCY", align: "right",},
          {text: this.$t('lastOrderDate'), value: "lastOrderDate", align: "right",},
          {text: this.$t('actions'), value: "actions", sortable: false, align: "center",},
        ];
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    applyToggleFilters: function () {
      //! MACHINES
      if(this.stat.tag == -1) {
        this.filters.orderStatisticalTagId = [];
        this.budgetMonitoringPost();  
        return;
      }
      const ost = [2, 7, 9, 10]; 
      if(!!this.stat.tag && !ost.includes(this.stat.tag)) { this.stat.type = null; }
      this.filters.orderStatisticalTagId = [this.stat.tag];
      this.filters.isLoanForUse = this.stat.type;
      if(!!this.stat.tag && !ost.includes(this.stat.tag)){
        this.budgetMonitoringPost();
      } else if(!!this.stat.tag && !!ost.includes(this.stat.tag) && typeof this.stat.type == 'boolean'){
        this.budgetMonitoringPost();
      }
    },
    isSelectedToggleFilters: function (p) {
      return this.toggleFilters === p ? 'selected-btn' : ''
    },
    openModal: function (customerMonitoringBudgetResponseDto) {
      this.selectedRow = customerMonitoringBudgetResponseDto;
      let opts = {
        customerId: [this.selectedRow.customerId],
        orderStatisticalTagId: !!this.filters.orderStatisticalTagId ? [this.filters.orderStatisticalTagId] : null
      };
      this.apiOrderStatisticalDataWithOrderStatisticalTagIdOrderStatisticalTagIdPost(opts)
          .then((res) => {
            this.showOrderRows = true;
          });
    },
    closeModalOrderRows: function () {
      this.showOrderRows = false;
    },
    initBudgetMonitoring: function () {
      this.initBudgetMonitoringPost(true);
    },
    initialize() {
      // this.apiStatisticalTagGet();
      this.apiOrderStatisticalTagGet();
      this.apiCustomerStatisticalTagGet();
      this.initPurchaseCategoryCollection();
      this.initBudgetMonitoring();
    },
    close() {
      this.dialog = false
    },
    onClearFilterEvent() {
      this.showFilters = false;
      this.initBudgetMonitoring();
    },
    handleExplode(explode) {
      this.apiOrderStatisticalDataWithOrderStatisticalTagIdOrderStatisticalTagIdPost({
        customerId: [this.selectedRow.customerId],
        orderStatisticalTagId: !!this.filters.orderStatisticalTagId ? [this.filters.orderStatisticalTagId] : null,
        explodeByGroupsCategories: explode
      });
    },
    openCustomerInNewTab(item) {
      window.open(`/customers/${item.customerId}`, '_blank');
    },
    showQuantity() {
      return !!this.filters.isLoanForUse;
    },
    downloadListExport: function () {
      this.apiListExport().then((res) => {
        this.downloadExcel(`customer_monitoring`, res);
      })
      .catch(error => {
      });
    },
    downloadExcel: function (filename, content) {
      var binaryData = [];
      binaryData.push(content);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    closeUpdateBudgetCY: function(x) {
      this.apiCustomerBudgetObjectivesAdjustCustomerObjectivePut({
        customerId: x.customerId,
        orderStatisticalTagId: this.filters.orderStatisticalTagId ?? 1,
        budgetValueObjective: x.budgetCY
      })
      .then((res) => {
      })
      .catch((e) => {
        console.error(e);
      })
    },  
    ...call("customerMonitoring/*"),
    ...call("customers/*"),
    ...call("statistics/*")
  },
};
</script>
<style>
.pill {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.pill > button {
  min-width: 30px !important;
}

.text-truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.number-style {
  font-size: 0.9rem;
  text-align: right;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 100px;
  min-height: 0;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-btn {
  color: #242f40 !important;
  font-size: larger;
}
</style>
